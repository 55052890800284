<div *ngIf="this.isdesktop==true" class="desktop">
   



    <div class="row">

    
    <div class="col-6">
        
            <img class="desktop_img" src="../../assets/krishnaprofile.jpg" alt="">
        
    </div>
    <div class="col-6">

    
    <div class="textdiv">
        <p class="boldclass">Hello, I'm Sai Krishna Raghunathan.</p>
        <br>
        <pre class="boldclass">I enjoy designing anything and everything
         a human can imagine.</pre>

        <br>
        <p class="lightclass">Actively working with Design, XR & AI | CXA<sup>TM</sup> Certified.</p>
        <br>
        <p>
            <a href="mailto:magicofspade@gmail.com" target="_blank"><svg style="color: gray;" version="1.0" id="Layer_1"
                    xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                    width="35px" height="35px" viewBox="0 0 100 100" enable-background="new 0 0 100 100"
                    xml:space="preserve">

                    <path d="M66.501,33.502C62.275,29.278,56.442,26.666,50,26.666c-12.884,0-23.333,10.447-23.333,23.333S37.116,73.334,50,73.334
    c11.748,0,21.445-8.697,23.066-20.001H50v-6.667h23.066H80v3.333c0,1.128-0.075,2.238-0.195,3.334
    C78.145,68.334,65.442,80.001,50,80.001c-16.569,0-30-13.431-30-30.002c0-16.567,13.431-30,30-30c8.284,0,15.784,3.359,21.214,8.789
    L66.501,33.502z" fill="gray" />

                </svg>
            </a>
            <a href="https://medium.com/@magicofspade" target="_blank">
            <svg class="iconsclass" version="1.0"
                id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px"
                y="0px" width="30px" height="30px" viewBox="0 0 100 100" enable-background="new 0 0 100 100"
                xml:space="preserve">
                <path d="M63.333,10L46.191,44.285L36.667,30L10,23.333v60L36.667,90V63.333L90,76.666v-60L63.333,10z M30,81.461l-13.333-3.333
	V31.872L30,35.205V81.461z M36.667,56.461V42.018l11.556,17.331L36.667,56.461z M59.075,63.613l-8.626-12.938l16.455-32.912
	l13.415,3.353L59.075,63.613z M83.333,68.128L66.387,63.89L83.333,30V68.128z" fill="gray" />
            </svg>
            </a>
            <a href="https://www.facebook.com/magicofspade/" target="_blank">
                <svg class="iconsclass" version="1.0" id="Layer_1" xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="30px" height="30px"
                    viewBox="0 0 100 100" enable-background="new 0 0 100 100" xml:space="preserve">
                    <path d="M83.333,10H16.667C13.001,10,10,13.001,10,16.667v66.667C10,86.998,13.001,90,16.667,90h66.666
	C86.999,90,90,86.998,90,83.334V16.667C90,13.001,86.999,10,83.333,10z M83.333,83.334H56.667V56.666h16.666V50H56.667v-6.667
	c0-5.524,4.479-10,10-10h6.666v-6.667h-6.666C57.464,26.667,50,34.126,50,43.333V50h-6.667v6.666H50v26.668H16.667V16.667h66.666
	V83.334z" fill="gray" />
                </svg>
            </a>
            <a href="http://m.me/magicofspade" target="_blank">
                <svg class="iconsclass" version="1.0" id="Layer_1" xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="30px" height="30px"
                    viewBox="0 0 100 100" enable-background="new 0 0 100 100" xml:space="preserve">
                    <path d="M50,10c-22.093,0-40,16.413-40,36.667c0,7.943,2.786,15.28,7.478,21.289l-7.224,17.436
	c-0.703,1.699,0.104,3.646,1.804,4.355c0.853,0.352,1.764,0.325,2.552,0l21.27-8.809c4.395,1.523,9.141,2.396,14.121,2.396
	c22.097,0,40-16.426,40-36.667C90,26.413,72.097,10,50,10z M50,76.666c-4.04,0-8.057-0.684-11.94-2.031l-2.393-0.826l-16.175,6.699
	l5.632-13.594l-2.393-3.061c-3.969-5.084-6.064-11.028-6.064-17.188c0-16.542,14.951-30,33.333-30c18.379,0,33.333,13.457,33.333,30
	C83.333,63.203,68.379,76.666,50,76.666z" fill="gray" />
                    <polygon points="70.573,41.38 65.859,36.667 55.286,47.236 44.714,36.667 29.427,51.953 34.144,56.666 44.714,46.094 55.286,56.666 
	" fill="gray" />
                </svg>
            </a>
            <a href="https://www.instagram.com/magicofspade/" target="_blank">
                <svg class="iconsclass" version="1.0" id="Layer_1" xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="30px" height="30px"
                    viewBox="0 0 100 100" enable-background="new 0 0 100 100" xml:space="preserve">
                    <path d="M70,9.999H30c-10.999,0-20,9.001-20,20v40.002c0,10.996,9.001,20,20,20h40c10.999,0,20-9.004,20-20V29.999
	C90,19,80.999,9.999,70,9.999z M83.333,70.001c0,7.35-5.979,13.333-13.333,13.333H30c-7.351,0-13.333-5.983-13.333-13.333V29.999
	c0-7.352,5.982-13.333,13.333-13.333h40c7.354,0,13.333,5.981,13.333,13.333V70.001z" fill="gray" />
                    <circle cx="71.667" cy="28.332" r="5" fill="gray" />
                    <path d="M50,29.999c-11.048,0-20,8.953-20,20c0,11.043,8.952,20.002,20,20.002c11.045,0,20-8.959,20-20.002
	C70,38.952,61.045,29.999,50,29.999z M50,63.334c-7.363,0-13.333-5.97-13.333-13.335S42.637,36.666,50,36.666
	s13.333,5.968,13.333,13.333S57.363,63.334,50,63.334z" fill="gray" />
                </svg>
            </a>
            <a href="https://www.linkedin.com/in/magicofspade/" target="_blank">
                <svg class="iconsclass" version="1.0" id="Layer_1" xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="30px" height="30px"
                    viewBox="0 0 100 100" enable-background="new 0 0 100 100" xml:space="preserve">
                    <path d="M83.333,10H16.667C13.001,10,10,13.001,10,16.667v66.667C10,86.998,13.001,90,16.667,90h66.666
	C86.999,90,90,86.998,90,83.334V16.667C90,13.001,86.999,10,83.333,10z M83.333,83.334H16.667V16.667h66.666V83.334z"
                        fill="gray" />
                    <rect x="30" y="43.333" width="6.667" height="26.667" fill="gray" />
                    <circle cx="33.333" cy="35" r="5" fill="gray" />
                    <path d="M56.667,43.333c-2.441,0-4.701,0.705-6.667,1.849V43.61h-6.667V70H50V56.666C50,52.984,52.985,50,56.667,50
	s6.666,2.984,6.666,6.666V70H70V56.666C70,49.303,64.03,43.333,56.667,43.333z" fill="gray" />
                </svg>
            </a>
            <a href="https://twitter.com/magicofspade" target="_blank">
                <svg class="iconsclass" version="1.0" id="Layer_1" xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="30px" height="30px"
                    viewBox="0 0 100 100" enable-background="new 0 0 100 100" xml:space="preserve">
                    <path d="M83.333,26.667C83.333,17.462,75.872,10,66.667,10C57.461,10,50,17.462,50,26.667v10c-14.421,0-27.305-6.549-35.866-16.824
	c-8.678,19.062-3.242,41.916,13.63,54.939c-4.717,4.229-10.935,6.82-17.764,6.82C17.562,86.881,26.745,90,36.667,90
	c25.777,0,46.666-20.898,46.666-46.667v-10L90,26.667H83.333z M76.667,43.333c0,22.058-17.943,40-40,40
	c-2.715,0-5.395-0.273-8.008-0.808c1.236-0.84,2.425-1.768,3.555-2.779l5.963-5.346l-6.341-4.896
	c-11.683-9.021-17.031-23.452-14.583-37.412c9.28,7.235,20.748,11.24,32.747,11.24h6.667V26.667c0-5.514,4.485-10,10-10
	c5.514,0,10,4.486,10,10V43.333z" fill="gray" />
                    <path d="M71.667,26.667c0,1.841-1.494,3.333-3.337,3.333C66.494,30,65,28.507,65,26.667s1.494-3.333,3.33-3.333
	C70.173,23.333,71.667,24.826,71.667,26.667z" fill="gray" />
                </svg>
            </a>


        </p>
    </div>
</div>


</div>


</div>
<div *ngIf="this.isdesktop!=true" class="mobile" style="text-align: center;">
    <div class="row" [ngStyle]="{'margin-top':margintop+'px'}">
        <div class="col-12">
            <img class="mobile_img" src="../../assets/krishnaprofile.jpg" alt="">
        </div>
    </div>
    <div class="row" style="margin-top: 30px;">
        <div class="col-12">
            <p class="boldclassmobile">Hello, I'm Sai Krishna Raghunathan.</p>
            <br>
            <p class="boldclassmobile">I enjoy designing anything and everything</p>
            <p class="boldclassmobile">a human can imagine.</p>

            <br>
            <p class="lightclass">Actively working with Design, XR & AI | CXA<sup>TM</sup> Certified.</p>
            <br>
            <p style="margin-top: 30px;">
                <a href="mailto:magicofspade@gmail.com" target="_blank"><svg style="color: gray;" version="1.0"
                        id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
                        x="0px" y="0px" width="30px" height="30px" viewBox="0 0 100 100"
                        enable-background="new 0 0 100 100" xml:space="preserve">

                        <path d="M66.501,33.502C62.275,29.278,56.442,26.666,50,26.666c-12.884,0-23.333,10.447-23.333,23.333S37.116,73.334,50,73.334
            c11.748,0,21.445-8.697,23.066-20.001H50v-6.667h23.066H80v3.333c0,1.128-0.075,2.238-0.195,3.334
            C78.145,68.334,65.442,80.001,50,80.001c-16.569,0-30-13.431-30-30.002c0-16.567,13.431-30,30-30c8.284,0,15.784,3.359,21.214,8.789
            L66.501,33.502z" fill="gray" />

                    </svg>
                </a>
                <a href="mailto:magicofspade@gmail.com" target="_blank">
                <svg class="iconsclass" (click)="openurlinnewtab('https://medium.com/@magicofspade')" version="1.0"
                    id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px"
                    y="0px" width="25px" height="25px" viewBox="0 0 100 100" enable-background="new 0 0 100 100"
                    xml:space="preserve">
                    <path d="M63.333,10L46.191,44.285L36.667,30L10,23.333v60L36.667,90V63.333L90,76.666v-60L63.333,10z M30,81.461l-13.333-3.333
            V31.872L30,35.205V81.461z M36.667,56.461V42.018l11.556,17.331L36.667,56.461z M59.075,63.613l-8.626-12.938l16.455-32.912
            l13.415,3.353L59.075,63.613z M83.333,68.128L66.387,63.89L83.333,30V68.128z" fill="gray" />
                </svg>
                </a>
                <a href="https://www.facebook.com/magicofspade/" target="_blank">
                    <svg class="iconsclass" version="1.0" id="Layer_1" xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="25px" height="25px"
                        viewBox="0 0 100 100" enable-background="new 0 0 100 100" xml:space="preserve">
                        <path d="M83.333,10H16.667C13.001,10,10,13.001,10,16.667v66.667C10,86.998,13.001,90,16.667,90h66.666
            C86.999,90,90,86.998,90,83.334V16.667C90,13.001,86.999,10,83.333,10z M83.333,83.334H56.667V56.666h16.666V50H56.667v-6.667
            c0-5.524,4.479-10,10-10h6.666v-6.667h-6.666C57.464,26.667,50,34.126,50,43.333V50h-6.667v6.666H50v26.668H16.667V16.667h66.666
            V83.334z" fill="gray" />
                    </svg>
                </a>
                <a href="http://m.me/magicofspade" target="_blank">
                    <svg class="iconsclass" version="1.0" id="Layer_1" xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="25px" height="25px"
                        viewBox="0 0 100 100" enable-background="new 0 0 100 100" xml:space="preserve">
                        <path d="M50,10c-22.093,0-40,16.413-40,36.667c0,7.943,2.786,15.28,7.478,21.289l-7.224,17.436
            c-0.703,1.699,0.104,3.646,1.804,4.355c0.853,0.352,1.764,0.325,2.552,0l21.27-8.809c4.395,1.523,9.141,2.396,14.121,2.396
            c22.097,0,40-16.426,40-36.667C90,26.413,72.097,10,50,10z M50,76.666c-4.04,0-8.057-0.684-11.94-2.031l-2.393-0.826l-16.175,6.699
            l5.632-13.594l-2.393-3.061c-3.969-5.084-6.064-11.028-6.064-17.188c0-16.542,14.951-30,33.333-30c18.379,0,33.333,13.457,33.333,30
            C83.333,63.203,68.379,76.666,50,76.666z" fill="gray" />
                        <polygon points="70.573,41.38 65.859,36.667 55.286,47.236 44.714,36.667 29.427,51.953 34.144,56.666 44.714,46.094 55.286,56.666 
            " fill="gray" />
                    </svg>
                </a>
                <a href="https://www.instagram.com/magicofspade/" target="_blank">
                    <svg class="iconsclass" version="1.0" id="Layer_1" xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="25px" height="25px"
                        viewBox="0 0 100 100" enable-background="new 0 0 100 100" xml:space="preserve">
                        <path d="M70,9.999H30c-10.999,0-20,9.001-20,20v40.002c0,10.996,9.001,20,20,20h40c10.999,0,20-9.004,20-20V29.999
            C90,19,80.999,9.999,70,9.999z M83.333,70.001c0,7.35-5.979,13.333-13.333,13.333H30c-7.351,0-13.333-5.983-13.333-13.333V29.999
            c0-7.352,5.982-13.333,13.333-13.333h40c7.354,0,13.333,5.981,13.333,13.333V70.001z" fill="gray" />
                        <circle cx="71.667" cy="28.332" r="5" fill="gray" />
                        <path d="M50,29.999c-11.048,0-20,8.953-20,20c0,11.043,8.952,20.002,20,20.002c11.045,0,20-8.959,20-20.002
            C70,38.952,61.045,29.999,50,29.999z M50,63.334c-7.363,0-13.333-5.97-13.333-13.335S42.637,36.666,50,36.666
            s13.333,5.968,13.333,13.333S57.363,63.334,50,63.334z" fill="gray" />
                    </svg>
                </a>
                <a href="https://www.linkedin.com/in/magicofspade/" target="_blank">
                    <svg class="iconsclass" version="1.0" id="Layer_1" xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="25px" height="25px"
                        viewBox="0 0 100 100" enable-background="new 0 0 100 100" xml:space="preserve">
                        <path
                            d="M83.333,10H16.667C13.001,10,10,13.001,10,16.667v66.667C10,86.998,13.001,90,16.667,90h66.666
            C86.999,90,90,86.998,90,83.334V16.667C90,13.001,86.999,10,83.333,10z M83.333,83.334H16.667V16.667h66.666V83.334z"
                            fill="gray" />
                        <rect x="30" y="43.333" width="6.667" height="26.667" fill="gray" />
                        <circle cx="33.333" cy="35" r="5" fill="gray" />
                        <path d="M56.667,43.333c-2.441,0-4.701,0.705-6.667,1.849V43.61h-6.667V70H50V56.666C50,52.984,52.985,50,56.667,50
            s6.666,2.984,6.666,6.666V70H70V56.666C70,49.303,64.03,43.333,56.667,43.333z" fill="gray" />
                    </svg>
                </a>
                <a href="https://twitter.com/magicofspade" target="_blank">
                    <svg class="iconsclass" version="1.0" id="Layer_1" xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="25px" height="25px"
                        viewBox="0 0 100 100" enable-background="new 0 0 100 100" xml:space="preserve">
                        <path d="M83.333,26.667C83.333,17.462,75.872,10,66.667,10C57.461,10,50,17.462,50,26.667v10c-14.421,0-27.305-6.549-35.866-16.824
            c-8.678,19.062-3.242,41.916,13.63,54.939c-4.717,4.229-10.935,6.82-17.764,6.82C17.562,86.881,26.745,90,36.667,90
            c25.777,0,46.666-20.898,46.666-46.667v-10L90,26.667H83.333z M76.667,43.333c0,22.058-17.943,40-40,40
            c-2.715,0-5.395-0.273-8.008-0.808c1.236-0.84,2.425-1.768,3.555-2.779l5.963-5.346l-6.341-4.896
            c-11.683-9.021-17.031-23.452-14.583-37.412c9.28,7.235,20.748,11.24,32.747,11.24h6.667V26.667c0-5.514,4.485-10,10-10
            c5.514,0,10,4.486,10,10V43.333z" fill="gray" />
                        <path d="M71.667,26.667c0,1.841-1.494,3.333-3.337,3.333C66.494,30,65,28.507,65,26.667s1.494-3.333,3.33-3.333
            C70.173,23.333,71.667,24.826,71.667,26.667z" fill="gray" />
                    </svg>
                </a>


            </p>
        </div>
    </div>
</div>
